const definitions = {
  rpc: {
    setKeys: {
      description: "Set session keys to the node",
      params: [{ name: "session_keys", type: "Bytes" }],
      type: "Null",
    },
  },
  types: {},
};

export default definitions;
