import { CircularProgress, Typography } from "@mui/material";
import type { PropsWithChildren } from "react";
import { Route, Routes } from "react-router";
import ErrorPage from "../pages/ErrorPage";
import FormPage from "../pages/FormPage";
import GreetingsPage from "../pages/GreetingsPage";
import Layout from "../pages/Layout";
import NotFoundPage from "../pages/NotFoundPage";
import { ConfigGuard, useConfigError } from "../subsystem/config/state";

const ConfigInitializing: React.FC = () => (
  <Layout>
    <CircularProgress />
    <Typography variant="body1">Loading the configuration file</Typography>
  </Layout>
);

const ErrorConfig = () => {
  const { error } = useConfigError();
  return <ErrorPage error={error} />;
};

const WithConfig: React.FC<PropsWithChildren> = ({ children }) => (
  <ConfigGuard
    uninit={<ConfigInitializing />}
    pending={<ConfigInitializing />}
    error={<ErrorConfig />}
    ready={<>{children}</>}
  />
);

const Root: React.FC = () => (
  <WithConfig>
    <Routes>
      <Route index element={<GreetingsPage />} />
      <Route path="/form" element={<FormPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </WithConfig>
);

export default Root;
