import type { Option } from "@polkadot/types";
import type { PalletTokenClaimsClaimInfo } from "@polkadot/types/lookup";
import type { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<"ready", Option<PalletTokenClaimsClaimInfo>>
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const TokenClaimsContext = Context;
export const TokenClaimsGuard = ComponentGuard;

export const useTokenClaims = createTaggedStateHook(
  TokenClaimsContext,
  "ready" as const
);
