import type { StateProps } from "@tagged-state/core";
import { PropsWithChildren, useEffect, useState } from "react";
import type { Observable } from "rxjs";
import { State, TokenClaimsContext } from "./state";

type HexString = `0x${string}`;

type TokenClaimsInitProps = PropsWithChildren & {
  ethereumAddress?: HexString;
  getClaims: (
    ethereumAddress: HexString
  ) => Observable<StateProps<State>["ready"]>;
};
const TokenClaimsInit: React.FC<TokenClaimsInitProps> = (props) => {
  const { children, ethereumAddress, getClaims } = props;
  const [value, setValue] = useState<State>({
    tag: "uninit",
    data: {},
  });

  useEffect(() => {
    if (!ethereumAddress) return;

    setValue({ tag: "pending", data: {} });
    getClaims(ethereumAddress).subscribe({
      next: (data) => {
        setValue({ tag: "ready", data });
      },
      error: (error) => {
        setValue({ tag: "error", data: { error } });
      },
    });
  }, [ethereumAddress, getClaims]);

  return <TokenClaimsContext.Provider value={value} children={children} />;
};

export default TokenClaimsInit;
