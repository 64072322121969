import type { ApiRx } from "@polkadot/api";
import {
  web3AccountsSubscribe,
  web3Enable,
  web3FromSource,
} from "@polkadot/extension-dapp";
import type {
  InjectedAccountWithMeta,
  InjectedExtension,
  MetadataDef,
} from "@polkadot/extension-inject/types";
import { decodeAddress } from "@polkadot/keyring";
import { u8aToHex } from "@polkadot/util";
import store from "store";

interface ExtensionProperties {
  extensionVersion: string;
  tokenDecimals: number;
  tokenSymbol: string;
  ss58Format?: number;
}

interface SavedProperties {
  [name: string]: ExtensionProperties;
}

export const substrateUpdate = async (
  api: ApiRx,
  { name, version, metadata }: InjectedExtension,
  metadataDef: MetadataDef
) => {
  if (!metadata) return;

  const known = await metadata.get();
  const found = known.find(
    (one) =>
      one.genesisHash === metadataDef.genesisHash &&
      one.specVersion === metadataDef.specVersion
  );

  if (found) return;
  if (!(await metadata.provide(metadataDef))) return;

  const storeKey = `properties:${api.genesisHash.toHex()}`;
  const allProperties = store.get(storeKey, {}) as SavedProperties;

  allProperties[name] = {
    extensionVersion: version,
    ss58Format: api.registry.chainSS58,
    tokenDecimals: api.registry.chainDecimals[0],
    tokenSymbol: api.registry.chainTokens[0],
  };

  store.set(storeKey, allProperties);
};

export const substrateEnable = async () => {
  const extensions = await web3Enable("Humanode token claims");

  return extensions.find((ext) => ext.name === "polkadot-js");
};

export const substrateAccountsSubscribe = async (
  cb: (addresses: InjectedAccountWithMeta[]) => void,
  ss58Format?: number
) => web3AccountsSubscribe(cb, { ss58Format });

type HexString = `0x${string}`;

export type SubstrateSignOptions = {
  substrateAddress: InjectedAccountWithMeta;
  data: string;
};

export const substrateSign = async (
  options: SubstrateSignOptions
): Promise<HexString> => {
  const { substrateAddress, data } = options;

  const injector = await web3FromSource(substrateAddress.meta.source);

  const signRaw = injector?.signer?.signRaw;

  if (!signRaw) throw new Error("Substrate signRaw is not established");

  const { signature } = await signRaw({
    address: substrateAddress.address,
    data: data,
    type: "bytes",
  });

  return signature;
};

export const addressToHex = (address: string) =>
  u8aToHex(decodeAddress(address));
