import {
  Alert,
  Box,
  Stack,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import type { u128 } from "@polkadot/types-codec";
import type { RegistryError } from "@polkadot/types-codec/types";
import type { PalletTokenClaimsEvent } from "@polkadot/types/lookup";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import Paper from "../lib/ui/Paper";
import { useApi } from "../subsystem/api/state";
import { msToString, toUnit } from "../subsystem/utils/display";
import type { ExtrinsicFailedType } from "../subsystem/utils/types";
import textsOnPage from "../textsOnPage";
import type { FormValues } from "./Form";
import type { StepData } from "./Steps";

const STEP_INDEX = 4;

type SuccessfulViewProps = {
  tokenClaimsEvent: PalletTokenClaimsEvent["asTokensClaimed"];
  decimals: number;
  token: string;
};
const SuccessfulView: React.FC<SuccessfulViewProps> = (props) => {
  const { tokenClaimsEvent, decimals, token } = props;

  const splittedBalanceString = useMemo(
    () => toUnit(tokenClaimsEvent.balance, decimals, token).split("."),
    [decimals, token, tokenClaimsEvent.balance]
  );

  const vesting = useMemo(() => {
    const toUnitCarried = (balance: u128) => toUnit(balance, decimals, token);

    return tokenClaimsEvent.vesting
      ? tokenClaimsEvent.vesting
          .toArray()
          .sort((a, b) =>
            a.cliff.toNumber() * 10000 + a.vesting.toNumber() >
            b.cliff.toNumber() * 10000 + b.vesting.toNumber()
              ? 1
              : -1
          )
          .map((vesting) => {
            const splittedVestingBalanceString = toUnitCarried(
              vesting.balance
            ).split(".");
            const vestingTimeString = msToString(0, vesting.vesting.toNumber());
            const cliffTimeString = msToString(0, vesting.cliff.toNumber());

            return (
              <Typography
                fontWeight={600}
                align="left"
                fontSize="1.25rem"
                key={vesting.toString()}
              >
                {splittedVestingBalanceString[0]}
                <Typography component="span" fontSize="1rem">
                  .{splittedVestingBalanceString[1]}
                </Typography>
                <Typography component="span" color="#a0a0a0">
                  {token} locked in{" "}
                </Typography>
                <Typography component="span">
                  vesting for {vestingTimeString}{" "}
                </Typography>
                {vesting.cliff.toNumber() ? (
                  <>
                    <Typography component="span" color="#a0a0a0">
                      with{" "}
                    </Typography>
                    <Typography component="span">
                      {cliffTimeString} cliff
                    </Typography>
                  </>
                ) : null}
              </Typography>
            );
          })
      : [];
  }, [decimals, token, tokenClaimsEvent.vesting]);

  return (
    <Paper>
      <Stack gap={1}>
        <Typography fontWeight={600} align="center" fontSize="2.25rem">
          {splittedBalanceString[0]}
          <Typography color="#a0a0a0" component="span" fontSize="1.5rem">
            .{splittedBalanceString[1]}
          </Typography>
          <Typography component="span" fontWeight={600}>
            {token}
          </Typography>
        </Typography>
        <Typography color="#33d9b2" align="center">
          was successfully claimed
        </Typography>
        <Stack gap={2} pl={4} pb={4}>
          <Stack>
            <Typography color="#a0a0a0">
              from the Ethereum address used on the public sale
            </Typography>
            <Typography>
              {tokenClaimsEvent.ethereumAddress.toString()}
            </Typography>
          </Stack>
          <Stack>
            <Typography color="#a0a0a0">to Substrate address</Typography>
            <Typography>{tokenClaimsEvent.who.toString()}</Typography>
          </Stack>
          {vesting?.length ? (
            <Stack>
              <Typography align="left" color="#a0a0a0">
                Including:
              </Typography>
              {vesting}
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </Paper>
  );
};

type FailureViewProps = {
  registryError: RegistryError;
};
const FailureView: React.FC<FailureViewProps> = (props) => {
  const { registryError } = props;

  const errors = useMemo(
    () =>
      registryError.docs.map((doc) => (
        <Alert key={doc} severity="error">
          {doc}
        </Alert>
      )),
    [registryError.docs]
  );

  return (
    <Stack>
      <Typography>This is caused by:</Typography>
      {errors}
    </Stack>
  );
};

const SomethingWentWrong: React.FC = () => (
  <Stack>
    <Typography>Claim has not correctly completed.</Typography>
    <Typography>This might be caused by:</Typography>
    <ul>
      <Typography component="li">
        no internet connectivity at the peer system or this device,
      </Typography>
      <Typography component="li">
        or maybe the peer is not currently running to accept a connection,
      </Typography>
    </ul>
  </Stack>
);

const Label: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <StepLabel
      StepIconProps={{
        style: {
          color:
            values.formParams.activeStep === STEP_INDEX ? "#90caf9" : "gray",
        },
      }}
      sx={{ pr: 4, pb: 1 }}
    >
      {textsOnPage.form.steps[STEP_INDEX].defaultStepLabel}
    </StepLabel>
  );
};

const Main: React.FC = () => {
  const { api } = useApi();
  const {
    values: { claimingEvents },
    setFieldValue,
  } = useFormikContext<FormValues>();

  const [Element, label] = useMemo(
    () =>
      claimingEvents.reduce(
        (acc, eventRecord) => {
          if (acc[1] !== "SOMETHING WENT WRONG") return acc;

          const type = `${eventRecord.event.data.section}.${eventRecord.event.data.method}`;

          switch (type) {
            case "tokenClaims.TokensClaimed":
              return [
                <SuccessfulView
                  tokenClaimsEvent={
                    eventRecord.event
                      .data as unknown as PalletTokenClaimsEvent["asTokensClaimed"]
                  }
                  decimals={api.registry.chainDecimals[0]}
                  token={api.registry.chainTokens[0]}
                />,
                "SUCCESS",
              ];
            case "system.ExtrinsicFailed":
              const mod = (
                eventRecord.event.data as unknown as ExtrinsicFailedType
              ).dispatchError.asModule;
              const registryError = api.registry.findMetaError(mod);
              return [<FailureView registryError={registryError} />, "FAILURE"];
            default:
              return acc;
          }
        },
        [<SomethingWentWrong />, "SOMETHING WENT WRONG"]
      ),
    [api.registry, claimingEvents]
  );

  useEffect(() => {
    setFieldValue(
      `textsOnPage.form.steps[${STEP_INDEX}].defaultStepLabel`,
      label
    );
  }, [label, setFieldValue]);

  return (
    <StepContent>
      <Box pb="15px">{Element}</Box>
    </StepContent>
  );
};

const CompleteStep: StepData = {
  main: Main,
  completed: () => null,
  label: Label,
};

export default CompleteStep;
