import {
  Button,
  FormHelperText,
  Stack,
  StepContent,
  StepLabel,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useCallback, useContext, useState } from "react";
import Section from "../lib/ui/Section";
import { SubstrateContext } from "../lib/wallet/substrate/connect";
import textsOnPage from "../textsOnPage";
import type { FormValues } from "./Form";
import type { StepData } from "./Steps";
import TokenClaims from "./TokenClaims";

const STEP_INDEX = 2;

type SignButtonState =
  | {
      tag: "uninit";
    }
  | {
      tag: "signing";
    }
  | {
      tag: "signed";
    }
  | {
      tag: "error";
      error: Error;
    };

const Label: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <StepLabel
      StepIconProps={{
        style: {
          color:
            values.formParams.activeStep === STEP_INDEX ? "#90caf9" : "gray",
        },
      }}
      sx={{ pr: 4, pb: 1 }}
    >
      {textsOnPage.form.steps[STEP_INDEX].defaultStepLabel}
    </StepLabel>
  );
};

const Main: React.FC = () => {
  const { values, submitForm, setFieldValue, setFieldError } =
    useFormikContext<FormValues>();
  const { substrateSign } = useContext(SubstrateContext);
  const [substrateSignState, setSubstrateSignState] = useState<SignButtonState>(
    { tag: "uninit" }
  );

  const substrateSignHanler = useCallback(() => {
    let signed = false;
    setSubstrateSignState({ tag: "signing" });
    substrateSign().subscribe({
      next: (events) => {
        setFieldValue("claimingEvents", events);

        if (signed) return;

        signed = true;
        submitForm();
      },
      error: (error) => setSubstrateSignState({ tag: "error", error }),
      complete: () => {
        setFieldError("formParams.complete", "true");
        setSubstrateSignState({ tag: "signed" });
        submitForm();
      },
    });
  }, [setFieldError, setFieldValue, submitForm, substrateSign]);

  return (
    <StepContent>
      <Stack gap={2} mt={2}>
        <Section
          label={
            textsOnPage.form.steps[STEP_INDEX].fieldLabels.substrateAddress
          }
          value={values.substrate.account?.address}
        />
        <Section
          label={
            textsOnPage.form.steps[STEP_INDEX].fieldLabels.substrateAddressHex
          }
          value={values.substrate.hex}
        />
        <Section
          label={textsOnPage.form.steps[STEP_INDEX].fieldLabels.ethereumAddress}
          value={values.ethereum.address}
        />
        <Section
          label={textsOnPage.form.steps[STEP_INDEX].fieldLabels.signature}
          value={values.signature}
        />
        <TokenClaims />
        <Stack>
          <Button
            id="claim.button"
            name="claim.button"
            variant="contained"
            disabled={substrateSignState.tag === "signing"}
            onClick={substrateSignHanler}
          >
            {textsOnPage.form.steps[STEP_INDEX].buttonText}
          </Button>
          {substrateSignState.tag === "error" && (
            <FormHelperText error>
              {substrateSignState.error.message}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
    </StepContent>
  );
};

const ClaimStep: StepData = {
  main: Main,
  completed: () => null,
  label: Label,
};

export default ClaimStep;
