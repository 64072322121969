import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import Layout from "./Layout";

const NotFoundPage: React.FC = () => (
  <Layout logo>
    <Container
      maxWidth="sm"
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Typography variant="h5" textAlign="center">
        Code: 404, Page not found
      </Typography>
    </Container>
  </Layout>
);

export default NotFoundPage;
