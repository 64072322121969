import { Button, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useConfig } from "../subsystem/config/state";
import Layout from "./Layout";

const GreetingsPage: React.FC = () => {
  const { environment } = useConfig();

  const Content = useMemo(
    () =>
      environment === "testnet" ? (
        <Stack gap={2}>
          <Typography fontWeight={600} variant="h5">
            Welcome human nodes and friends!
          </Typography>
          <Typography variant="body1">
            Thanks for joining the claim tests.
          </Typography>
          <Typography variant="body1">
            Here you will be able to test out and prepare yourselves for the
            real claimsthat will happen at TGE.
          </Typography>
          <Typography variant="body1">
            You will also be bringing a tremendous value to our devs as they'd
            be able to iron out all the bugs that might pop up!
          </Typography>
          <Typography variant="body1">
            Keep in mind that the cliff and vesting periods are already attached
            to respective wallets and are calculated from the genesis not from
            the claim itself!
          </Typography>
        </Stack>
      ) : (
        <Stack gap={2}>
          <Typography fontWeight={600} variant="h5">
            Welcome human nodes and friends!
          </Typography>
          <Typography>
            Thanks for setting forth on this journey with us.
          </Typography>
          <Typography variant="body1">
            Here you will be able to claim your long-awaited tokens.
          </Typography>
          <Typography variant="body1">
            Keep in mind that the cliff and vesting periods are already attached
            to respective wallets and are calculated from the genesis not from
            the claim itself!
          </Typography>
        </Stack>
      ),
    [environment]
  );

  return (
    <Layout>
      <Stack
        sx={{
          height: "100vh",
          justifyContent: "center",
          gap: 3,
          maxWidth: "530px",
        }}
      >
        {Content}
        <Button component={Link} to="./form">
          I understand
        </Button>
      </Stack>
    </Layout>
  );
};

export default GreetingsPage;
