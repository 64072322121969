import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import ErrorPage from "../pages/ErrorPage";
import ConfigInit from "../subsystem/config/ConfigInit";
import Root from "./Root";

const Entrypoint: React.FC = () => (
  <BrowserRouter>
    <ErrorBoundary renderError={ErrorPage}>
      <ConfigInit>
        <Root />
      </ConfigInit>
    </ErrorBoundary>
  </BrowserRouter>
);

export default Entrypoint;
