import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Alert,
  Button,
  Drawer,
  IconButton,
  Portal,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { PropsWithChildren, useCallback, useContext, useState } from "react";

import { ApiContext } from "../subsystem/api/state";
import { useConfig } from "../subsystem/config/state";
import * as typedLocalStorage from "../subsystem/localStorage";
import textsOnPage from "../textsOnPage";

const ApiConnectionSettingLayer: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [open, setOpen] = useState(false);
  const { baseUrl } = useConfig();
  const {
    data: { setConnectionUrl },
  } = useContext(ApiContext);

  const [url, setUrl] = useState<string>(
    typedLocalStorage.getItem("connectionUrl") || baseUrl
  );

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const reconnectHandler = useCallback(() => {
    if (!url) return;

    setConnectionUrl(url);
  }, [setConnectionUrl, url]);

  const resetToDefaultHandler = useCallback(() => {
    setUrl(baseUrl);
  }, [baseUrl]);

  return (
    <>
      <Portal>
        <Box sx={{ position: "fixed", top: 0, right: 0 }}>
          <IconButton sx={{ m: 1 }} onClick={handleOpen}>
            <SettingsIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            sx={{ width: "400px" }}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton sx={{ m: 1 }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Alert severity="info" sx={{ pl: 4, pr: 3, maxWidth: "400px" }}>
              {textsOnPage.settings.description}
            </Alert>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                pt: 2,
                pl: 4,
                pr: 2,
              }}
            >
              <TextField
                fullWidth
                label={textsOnPage.settings.urlLabel}
                value={url}
                onChange={(event) => setUrl(event.target.value)}
              />
              <Button
                disabled={url === baseUrl}
                onClick={resetToDefaultHandler}
              >
                Reset
              </Button>
            </Box>
            <Button onClick={reconnectHandler} sx={{ ml: 4, mr: 4, mt: 2 }}>
              Connect
            </Button>
          </Drawer>
        </Box>
      </Portal>
      {children}
    </>
  );
};

export default ApiConnectionSettingLayer;
