import type { ApiRx } from "@polkadot/api";
import type { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";
import type { Observable } from "rxjs";

type StateBase = { setConnectionUrl: (connectionUrl: string) => void };
export type State =
  | StateVariant<"uninit", StateBase>
  | StateVariant<"establishing", StateBase & { api: Observable<ApiRx> }>
  | StateVariant<"disconnected", StateBase & { api: ApiRx }>
  | StateVariant<"ready", StateBase & { api: ApiRx }>
  | StateVariant<"error", StateBase & { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: { setConnectionUrl: () => {} },
});

export const ApiContext = Context;
export const ApiGuard = ComponentGuard;

export const useApi = createTaggedStateHook(Context, "ready" as const);
