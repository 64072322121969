import { Step } from "@mui/material";
import Connector from "../lib/ui/Connector";
import ClaimStep from "./ClaimStep";
import CompleteStep from "./CompleteStep";
import EthereumStep from "./EthereumStep";
import SubstrateStep from "./SubstrateStep";
import TransactionProcessingStep from "./TransactionProcessingStep";

export type StepData = {
  main: React.FC;
  completed: React.FC;
  label: React.FC;
};

const makeSteps = (steps: StepData[]) =>
  steps.map((stepData, index) => {
    const MainComponent = stepData.main;
    const Label = stepData.label;
    const CompletedComponent = steps[index - 1]?.completed;
    return (
      <Step key={index}>
        {CompletedComponent && (
          <Connector>
            <CompletedComponent />
          </Connector>
        )}
        <Label />
        <MainComponent />
      </Step>
    );
  });

export default makeSteps([
  SubstrateStep,
  EthereumStep,
  ClaimStep,
  TransactionProcessingStep,
  CompleteStep,
]);
