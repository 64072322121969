import { ReplaySubject } from "rxjs";
import { useSubscription } from "observable-hooks";

export type ErrorData = {
  name: string;
  message: string;
  display: string;
};

const BUFFER_LAST_N_ERRORS = 100;
const BUFFER_FOR_MS = 500;
const subject = new ReplaySubject<ErrorData>(
  BUFFER_LAST_N_ERRORS,
  BUFFER_FOR_MS
);

export type OnAppError = (error: ErrorData) => void;

export const useAppErrorsSubscription = (callback: OnAppError) => {
  useSubscription(subject, callback);
};

export const addAppErrorData = (error: ErrorData) => subject.next(error);

export const addAppError = (error: Error) =>
  addAppErrorData({
    display: error.toString(),
    name: error.name,
    message: error.message,
  });
