import type { EIP712Domain } from "eip-712";

const EIP712DomainTypeValue = [
  { name: "name", type: "string" },
  { name: "version", type: "string" },
  { name: "chainId", type: "uint256" },
  { name: "verifyingContract", type: "address" },
] as const;

export type EIP712DomainType = typeof EIP712DomainTypeValue;

type HexString = `0x${string}`;

export type BuildMessageParams = {
  domain: EIP712Domain;
  substrateAddress: HexString;
};

type Writable<T> = {
  -readonly [P in keyof T]: T[P];
};

export type Message = {
  primaryType: string;
  domain: EIP712Domain;
  message: { substrateAddress: HexString };
  types: {
    EIP712Domain: Writable<EIP712DomainType>;
    TokenClaim: { name: string; type: string }[];
  };
};

export const buildMessage = ({
  domain,
  substrateAddress,
}: BuildMessageParams): Message => ({
  primaryType: "TokenClaim",
  domain,
  message: {
    substrateAddress,
  },
  types: {
    EIP712Domain: [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ],
    TokenClaim: [{ name: "substrateAddress", type: "bytes" }],
  },
});
