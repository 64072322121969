import React, { useCallback, useState } from "react";
import Slide, { SlideProps } from "@mui/material/Slide";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { ErrorData, useAppErrorsSubscription } from "./appErrors";

const SlideTransition: React.FC<SlideProps> = (props) => {
  return <Slide {...props} direction="up" />;
};

const AppErrorsSnackbar: React.FC = () => {
  const [errors, setErrors] = useState<
    Array<{ id: string; errorData: ErrorData }>
  >([]);

  const handleAddError = useCallback(
    (error: ErrorData) => {
      const newId = Math.random() + "-" + Date.now();
      setErrors((errs) => [...errs, { errorData: error, id: newId }]);
    },
    [setErrors]
  );
  const handleRemoveError = useCallback(
    (id: string) => {
      setErrors((errs) => errs.filter((v) => v.id !== id));
    },
    [setErrors]
  );

  // Connect app errors stream to the local state.
  useAppErrorsSubscription(handleAddError);

  return (
    <>
      {errors.map((error) => (
        <SnackBarWrapper
          key={error.id}
          id={error.id}
          message={error.errorData.display}
          onClose={handleRemoveError}
        />
      ))}
    </>
  );
};

const SnackBarWrapper: React.FC<{
  id: string;
  message: string;
  onClose: (id: string) => void;
}> = ({ id, message, onClose }) => {
  const handleClose = useCallback(
    (_event: unknown, reason: SnackbarCloseReason) => {
      if (reason === "clickaway") {
        return;
      }
      onClose(id);
    },
    [id, onClose]
  );
  const handleCloseButtonClick = useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  return (
    <Snackbar
      key={id}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      message={message}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleCloseButtonClick}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default AppErrorsSnackbar;
