import { Alert, Skeleton, Stack, Typography } from "@mui/material";
import type { u128 } from "@polkadot/types-codec";
import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import Paper from "../lib/ui/Paper";
import { useApi } from "../subsystem/api/state";
import {
  TokenClaimsGuard,
  useTokenClaims,
} from "../subsystem/tokenClaims/state";
import { msToString, toUnit } from "../subsystem/utils/display";
import type { FormValues } from "./Form";

const TokenClaimPending: React.FC = () => (
  <Skeleton animation="wave" height={130} />
);

const TokenClaimError: React.FC<{ error: Error }> = ({ error }) => (
  <Paper>
    <Typography color="error">{error.message}</Typography>
  </Paper>
);

const TokenClaimReady: React.FC = () => {
  const { api } = useApi();
  const claimInfo = useTokenClaims();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const ClaimInfoComponent = useMemo(() => {
    const decimals = api.runtimeChain.registry.chainDecimals[0];
    const token = api.runtimeChain.registry.chainTokens[0];

    const hasVesting =
      claimInfo.value.vesting && claimInfo.value.vesting.length;
    const toUnitCarried = (balance: u128) => toUnit(balance, decimals, token);
    const splittedBalanceString = toUnitCarried(claimInfo.value.balance).split(
      "."
    );

    const vesting = hasVesting
      ? claimInfo.value.vesting
          .toArray()
          .sort((a, b) =>
            a.cliff.toNumber() * 10000 + a.vesting.toNumber() >
            b.cliff.toNumber() * 10000 + b.vesting.toNumber()
              ? 1
              : -1
          )
          .map((vesting) => {
            const splittedVestingBalanceString = toUnitCarried(
              vesting.balance
            ).split(".");
            const vestingTimeString = msToString(0, vesting.vesting.toNumber());
            const cliffTimeString = msToString(0, vesting.cliff.toNumber());

            return (
              <Typography
                key={vesting.toString()}
                fontWeight={600}
                align="left"
                fontSize="1.25rem"
              >
                {splittedVestingBalanceString[0]}
                <Typography component="span" fontSize="1rem">
                  .{splittedVestingBalanceString[1]}
                </Typography>
                <Typography component="span" color="#a0a0a0">
                  {token} locked in{" "}
                </Typography>
                <Typography component="span">
                  vesting for {vestingTimeString}{" "}
                </Typography>
                {vesting.cliff.toNumber() ? (
                  <>
                    <Typography component="span" color="#a0a0a0">
                      with{" "}
                    </Typography>
                    <Typography component="span">
                      {cliffTimeString} cliff
                    </Typography>
                  </>
                ) : null}
              </Typography>
            );
          })
      : [];

    return (
      <Stack>
        <Typography align="center" fontSize="0.75rem" color="#a0a0a0">
          Your Ethereum address is eligible to claim
        </Typography>
        <Typography fontWeight={600} align="center" fontSize="2.25rem">
          {splittedBalanceString[0]}
          <Typography color="#a0a0a0" component="span" fontSize="1.5rem">
            .{splittedBalanceString[1]}
          </Typography>
          <Typography fontWeight={600} component="span">
            {token}
          </Typography>
        </Typography>
        {vesting?.length ? (
          <Stack sx={{ pl: 4 }}>
            <Typography align="left" fontSize="0.75rem" color="#a0a0a0">
              Including:
            </Typography>
            {vesting}
          </Stack>
        ) : null}
        {vesting?.length ? (
          <Alert sx={{ pl: 4 }} severity="warning">
            Keep in mind that the cliff and vesting periods are calculated from
            the genesis not from the moment of the claim!
          </Alert>
        ) : null}
      </Stack>
    );
  }, [
    api.runtimeChain.registry.chainDecimals,
    api.runtimeChain.registry.chainTokens,
    claimInfo,
  ]);

  useEffect(() => {
    if (claimInfo.value.isEmpty !== values.nothingToClaim) {
      setFieldValue("nothingToClaim", claimInfo.value.isEmpty);
    }
  }, [setFieldValue, values.nothingToClaim, claimInfo.value.isEmpty]);

  return (
    <Stack>
      <Paper>
        {claimInfo.value.isEmpty ? (
          <Typography>Nothing to claim</Typography>
        ) : (
          ClaimInfoComponent
        )}
      </Paper>
    </Stack>
  );
};

const TokenClaims: React.FC = () => (
  <TokenClaimsGuard
    uninit={TokenClaimPending}
    pending={TokenClaimPending}
    ready={TokenClaimReady}
    error={TokenClaimError}
  />
);

export default TokenClaims;
