import type { StateVariant } from "@tagged-state/core";
import type { ContextComponentGuardProps } from "@tagged-state/react";
import Guard from "@tagged-state/react/dist/ComponentGuard";
import { useSubscription } from "observable-hooks";
import { useState } from "react";
import { BehaviorSubject, interval, startWith } from "rxjs";

interface Health {
  isSyncing: {
    isTrue: boolean;
  };
}

export type State =
  | StateVariant<"uninit">
  | StateVariant<"syncing">
  | StateVariant<"synced">
  | StateVariant<"error", { error: Error }>;

const initialTaggedState: State = {
  tag: "uninit",
  data: {},
};
const subject = new BehaviorSubject<State>(initialTaggedState);

export type OnState = (taggedState: State) => void;

export const useSyncStateSubscription = (callback: OnState) => {
  useSubscription(subject, callback);
};

export const setTaggedState = (state: State) => subject.next(state);

const interval$ = interval(1000).pipe(startWith(0));

type Params = {
  systemHealth: () => Promise<Health>;
};
const useSyncState = (params: Params) => {
  const { systemHealth } = params;

  useSubscription(interval$, async () => {
    try {
      const health = await systemHealth();
      const isSyncing = health.isSyncing.isTrue;

      if (isSyncing) {
        setTaggedState({
          tag: "syncing",
          data: {},
        });
        return;
      }

      setTaggedState({
        tag: "synced",
        data: {},
      });
    } catch (error) {
      setTaggedState({
        tag: "error",
        data: { error: error as Error },
      });
    }
  });

  return null;
};

export const SyncStateGuard: React.FC<
  ContextComponentGuardProps<State, any>
> = (props) => {
  const [syncState, setSyncState] = useState<State>(initialTaggedState);

  useSyncStateSubscription(
    (newState) => newState.tag !== syncState.tag && setSyncState(newState)
  );

  return <Guard taggedState={syncState} {...props} />;
};

export default useSyncState;
