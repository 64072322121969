import { Box, Button, FormHelperText, Stack } from "@mui/material";
import { u8aToHex } from "@polkadot/util";
import { useFormikContext } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import FieldViewOnly from "../lib/ui/FieldViewOnly";
import Paper from "../lib/ui/Paper";
import Section from "../lib/ui/Section";
import { EthereumContext } from "../lib/wallet/ethereum/connect";
import { NETWORK } from "../lib/wallet/ethereum/hooks/useRequiredNetwork";
import { useApi } from "../subsystem/api/state";
import { useConfig } from "../subsystem/config/state";
import { buildMessage } from "../subsystem/eip712";
import type { DeepRequired } from "../subsystem/utils/types";
import textsOnPage from "../textsOnPage";
import type { FormValues } from "./Form";
import TokenClaims from "./TokenClaims";

const STEP_INDEX = 1;
const TAB_INDEX = 0;

type SignButtonState =
  | {
      tag: "uninit";
    }
  | {
      tag: "signing";
    }
  | {
      tag: "signed";
    }
  | {
      tag: "error";
      error: Error;
    };

const Eip712Web3WalletSignTab: React.FC = () => {
  const { api } = useApi();
  const { values, setFieldValue, submitForm } = useFormikContext<FormValues>();
  const {
    sign: ethereumSign,
    ethereumAddress,
    requiredNetwork,
    switchNetwork,
  } = useContext(EthereumContext);
  const { eip712domain } = useConfig();
  const [signButtonState, setSignButtonState] = useState<SignButtonState>({
    tag: "uninit",
  });

  const {
    addEthereumChainParameter: { chainId, chainName },
  } = useConfig();

  useEffect(() => {
    if (values.formParams.tabIndex !== TAB_INDEX) return;

    setFieldValue("ethereum.address", ethereumAddress);

    if (values.signature) {
      submitForm();
    }
  }, [
    ethereumAddress,
    setFieldValue,
    submitForm,
    values.signature,
    values.formParams.tabIndex,
  ]);

  const ethereumSignHandler = useCallback(async () => {
    const formValues = values as DeepRequired<FormValues>;
    const data = buildMessage({
      domain: {
        ...eip712domain,
        verifyingContract: u8aToHex(api.genesisHash.toU8a().slice(0, 20)),
      },
      substrateAddress: formValues.substrate.hex,
    });

    try {
      setSignButtonState({ tag: "signing" });
      const signature = await ethereumSign({
        ethereumAddress: formValues.ethereum.address,
        data,
      });

      setSignButtonState({ tag: "signed" });
      setFieldValue("signature", signature);
    } catch (error) {
      setSignButtonState({ tag: "error", error: error as Error });
    }
  }, [api.genesisHash, eip712domain, ethereumSign, setFieldValue, values]);

  if (requiredNetwork !== NETWORK.REQUIRED) {
    return (
      <Paper>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <FieldViewOnly
            label={
              textsOnPage.form.steps[STEP_INDEX].tabs?.[0].fieldLabels
                .ethereumAddress
            }
            formikPath="ethereum.address"
            value="You are on the wrong chain"
          />
          <Button fullWidth onClick={switchNetwork}>
            SWITCH NETWORK
          </Button>
        </Box>
      </Paper>
    );
  }

  return (
    <Stack gap={2} pt={4}>
      <Section
        value={`${chainId} (${chainName})`}
        label={
          textsOnPage.form.steps[STEP_INDEX].tabs?.[TAB_INDEX].fieldLabels
            .chainId
        }
      />
      <Section
        label={
          textsOnPage.form.steps[STEP_INDEX].tabs?.[TAB_INDEX].fieldLabels
            .ethereumAddress
        }
        value={
          values.ethereum.address || "You did not select any ethereum address"
        }
      />
      <TokenClaims />
      <Stack>
        <Button
          variant="outlined"
          disabled={
            requiredNetwork !== NETWORK.REQUIRED ||
            !values.ethereum.address ||
            values.nothingToClaim ||
            signButtonState.tag === "signing"
          }
          onClick={ethereumSignHandler}
        >
          {textsOnPage.form.steps[STEP_INDEX].tabs?.[TAB_INDEX].buttonText}
        </Button>
        {signButtonState?.tag === "error" && (
          <FormHelperText error>{signButtonState.error.message}</FormHelperText>
        )}
      </Stack>
    </Stack>
  );
};

export default Eip712Web3WalletSignTab;
