import { Alert, Box, Button, Stack, TextField } from "@mui/material";
import Link from "@mui/material/Link";
import { u8aToHex } from "@polkadot/util";
import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import Section from "../lib/ui/Section";
import { useApi } from "../subsystem/api/state";
import { useConfig } from "../subsystem/config/state";
import { buildMessage } from "../subsystem/eip712";
import textsOnPage from "../textsOnPage";
import type { FormValues } from "./Form";
import TokenClaims from "./TokenClaims";

const STEP_INDEX = 1;
const TAB_INDEX = 1;

const Eip712ManualSignTab: React.FC = () => {
  const { api } = useApi();
  const {
    values: {
      ethereum: { address: ethereumAddress },
      substrate: { hex: substrateHex },
      eip712,
      nothingToClaim,
      formParams: { tabIndex },
    },
    errors,
    setFieldValue,
    handleChange,
    submitForm,
  } = useFormikContext<FormValues>();

  const { eip712domain } = useConfig();

  useEffect(() => {
    if (tabIndex !== TAB_INDEX) return;

    if (eip712.ethereumAddress && !errors.eip712?.ethereumAddress) {
      setFieldValue("ethereum.address", eip712.ethereumAddress);
    } else {
      setFieldValue("ethereum.address", undefined);
    }

    if (eip712.signature && !errors.eip712?.signature) {
      setFieldValue("signature", eip712.signature);
    } else {
      setFieldValue("signature", undefined);
    }
  }, [
    errors.eip712?.ethereumAddress,
    errors.eip712?.signature,
    eip712.ethereumAddress,
    eip712.signature,
    setFieldValue,
    tabIndex,
  ]);

  const eip712data = useMemo(
    () =>
      substrateHex &&
      buildMessage({
        domain: {
          ...eip712domain,
          verifyingContract: u8aToHex(api.genesisHash.toU8a().slice(0, 20)),
        },
        substrateAddress: substrateHex,
      }),
    [api.genesisHash, eip712domain, substrateHex]
  );

  return (
    <Stack gap={2}>
      <Alert severity="info">
        You can sign the message via your wallet If it supports{" "}
        <Link href="https://eips.ethereum.org/EIPS/eip-712">
          EIP-712 format
        </Link>
        .
      </Alert>
      <Section
        value={JSON.stringify(eip712data, null, "  ")}
        label={
          textsOnPage.form.steps[STEP_INDEX].tabs?.[TAB_INDEX].fieldLabels
            .message
        }
        mono
        maxLines={5}
        rightAction={
          <Button
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(eip712data));
            }}
          >
            COPY
          </Button>
        }
      />
      <Box>
        <TextField
          fullWidth
          error={Boolean(errors.eip712?.ethereumAddress)}
          label={
            textsOnPage.form.steps[STEP_INDEX].tabs?.[TAB_INDEX].fieldLabels
              .ethereumAddress
          }
          id="eip712.ethereumAddress"
          name="eip712.ethereumAddress"
          onChange={handleChange}
          helperText={errors.eip712?.ethereumAddress}
        />
      </Box>
      <TextField
        fullWidth
        error={Boolean(errors.eip712?.signature)}
        helperText={errors.eip712?.signature}
        label={
          textsOnPage.form.steps[STEP_INDEX].tabs?.[TAB_INDEX].fieldLabels
            .signature
        }
        id="eip712.signature"
        name="eip712.signature"
        onChange={handleChange}
      />
      <TokenClaims />
      <Stack>
        <Button
          variant="outlined"
          disabled={
            nothingToClaim ||
            !ethereumAddress ||
            !eip712.signature ||
            Boolean(errors.eip712?.signature) ||
            Boolean(errors.eip712?.ethereumAddress)
          }
          onClick={submitForm}
        >
          {textsOnPage.form.steps[STEP_INDEX].tabs?.[TAB_INDEX].buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};

export default Eip712ManualSignTab;
