import type { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import type {
  AddEthereumChainParameter,
  SwitchEthereumChainParameter,
} from "../eips";

export const getWeb3Provider = () => {
  if (!window.ethereum) {
    throw new Error("Unable ethereum wallet provider");
  }

  return new ethers.providers.Web3Provider(window.ethereum);
};

type HexString = `0x${string}`;

export type EthereumSignOptions = {
  ethereumAddress: HexString;
  data: Object;
};

export const ethereumSign = async (
  provider: Web3Provider,
  options: EthereumSignOptions
): Promise<HexString> => {
  const { ethereumAddress, data } = options;

  // We don't use ethers signer._signTypedData cause experimental feature at this moment.
  return await provider.send("eth_signTypedData_v4", [
    ethereumAddress,
    JSON.stringify(data),
  ]);
};

export const switchEthereumNetwork = async (
  provider: Web3Provider,
  addEthereumChainParameter: AddEthereumChainParameter
) => {
  try {
    await provider.send("wallet_switchEthereumChain", [
      {
        chainId: addEthereumChainParameter.chainId,
      } as SwitchEthereumChainParameter,
    ]);
  } catch (e) {
    await provider.send("wallet_addEthereumChain", [addEthereumChainParameter]);
    await provider.send("wallet_switchEthereumChain", [
      {
        chainId: addEthereumChainParameter.chainId,
      } as SwitchEthereumChainParameter,
    ]);
  }
};
