const shared = {
  substrateAddress: "Substrate address you want to recieve the tokens to",
  substrateAddressHex:
    "Hex representation of the substrate address you want to recieve the tokens to",
  ethereumAddress: "Ethereum address you used on the public sale",
  ethereumSignature: "Ethereum signature",
};

const textsOnPage = {
  settings: {
    urlLabel: "Humanode RPC URL",
    description: "Select the node you want to connect to",
  },
  claimInfo: {
    empty: "Nothing to claim",
  },
  form: {
    validation: {
      manual: {
        ethereumAddress: "Available hex value only",
        signature: "Available hex value only",
      },
      eip712: {
        ethereumAddress: "Available hex value only",
        signature: "Available hex value only",
      },
    },
    steps: [
      {
        defaultStepLabel: "SELECT SUBSTRATE ADDRESS",
        description: "Select a substrate address to recieve the tokens to.",
        fieldLabels: {
          substrateAddress: shared.substrateAddress,
          substrateAddressHex: shared.substrateAddressHex,
        },
        buttonText: "Next",
        completed: {
          description:
            "You have selected this Substrate address to receive the tokens to:",
          getBackButtonText: "Select another",
        },
      },
      {
        defaultStepLabel: "SELECT ETH ADDRESS",
        descriptions: [
          "Sign a token claim request message with the ethereum address you have on the public sale.",
          "This action does not send a transaction to the Ethereum network. This signature will be used on the Humanode network to confirm the ownership of the public sale address.",
        ],
        signDescription: "This is not signing any transaction",
        fieldLabels: {},
        tabs: [
          {
            name: "Web3 wallet",
            fieldLabels: {
              chainId: "Chain ID",
              ethereumAddress: shared.ethereumAddress,
            },
            buttonText: "Sign with ethereum",
          },
          {
            name: "Eip-712",
            fieldLabels: {
              message: "EIP-712 message",
              ethereumAddress: shared.ethereumAddress,
              signature: shared.ethereumSignature,
            },
            buttonText: "Confirm signature",
          },
          {
            name: "Manual signing",
            fieldLabels: {
              message: "Message",
              ethereumAddress: shared.ethereumAddress,
              signature: shared.ethereumSignature,
            },
            buttonText: "Confirm signature",
          },
        ],
        completed: {
          description: [
            "You've signed a token claim request message via Web3 wallet with the Ethereum address you have used on the public sale:",
            "You've manually signed a token claim request EIP-712 message with the Ethereum address you have used on the public sale:",
            "You've manually signed a token claim request message with the Ethereum address you have used on the public sale:",
          ],
        },
      },
      {
        defaultStepLabel: "SEND THE TOKEN CLAIM TRANSACTION",
        fieldLabels: {
          substrateAddress: shared.substrateAddress,
          substrateAddressHex: shared.substrateAddressHex,
          ethereumAddress: shared.ethereumAddress,
          signature: shared.ethereumSignature,
        },
        buttonText: "SEND TOKEN CLAIM TRANSACTION",
        completed: {},
      },
      {
        defaultStepLabel: "TOKEN CLAIM TRANSACTION PROCESSING EVENTS",
        fieldLabels: {},
        buttonText: "",
        completed: {
          toggleText: ["Show logs", "Hide logs"],
        },
      },
      {
        defaultStepLabel: "COMPLETE",
        fieldLabels: {},
        buttonText: "",
        completed: {},
      },
    ],
  },
};

export default textsOnPage;
