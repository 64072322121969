const definitions = {
  types: {
    FacetecDeviceSdkParams: {
      deviceKeyIdentifier: "Text",
      publicFaceMapEncryptionKey: "Text",
      productionKey: "Option<Text>",
    },
    LivenessData: {
      faceScan: "Text",
      auditTrailImage: "Text",
      lowQualityAuditTrailImage: "Text",
    },
    UnixMilliseconds: "u64",
    BioauthStatusActive: {
      expiresAt: "UnixMilliseconds",
    },
    BioauthStatus: {
      _enum: {
        Unknown: null,
        Inactive: null,
        Active: "BioauthStatusActive",
      },
    },
  },
  rpc: {
    getFacetecDeviceSdkParams: {
      description: "Get FaceTec device sdk params",
      params: [],
      type: "FacetecDeviceSdkParams",
    },
    provideLivenessData: {
      description: "Provide the liveness data to the node",
      params: [{ name: "liveness_data", type: "LivenessData" }],
      type: "Null",
    },
    enroll: {
      description: "Enroll with the provided liveness data",
      params: [{ name: "liveness_data", type: "LivenessData" }],
      type: "Null",
    },
    authenticate: {
      description: "Authenticate with the provided liveness data",
      params: [{ name: "liveness_data", type: "LivenessData" }],
      type: "Null",
    },
    getFacetecSessionToken: {
      description: "Get FaceTec session token",
      params: [],
      type: "Text",
    },
    status: {
      description: "Rpc method to get bioauth status",
      params: [],
      type: "BioauthStatus<Timestamp>",
    },
  },
};

export default definitions;
