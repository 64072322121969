import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import type { ApiRx } from "@polkadot/api";
import type { u128, Vec } from "@polkadot/types-codec";
import type { Codec } from "@polkadot/types-codec/types";
import type { AccountId32 } from "@polkadot/types/interfaces/runtime";
import type {
  DispatchInfo,
  EventRecord,
} from "@polkadot/types/interfaces/system";
import type {
  FrameSupportWeightsDispatchInfo,
  VestingScheduleLinearLinearSchedule,
} from "@polkadot/types/lookup";
import { useFormikContext } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import type { FormValues } from "../components/Form";
import DashedBordered from "../lib/ui/DashedBordered";
import Paper from "../lib/ui/Paper";
import TabComponent from "../lib/ui/TabComponent";
import { useApi } from "../subsystem/api/state";
import { useConfig } from "../subsystem/config/state";
import { toUnit } from "../subsystem/utils/display";
import { ExtrinsicFailedType, ExtrinsicType } from "../subsystem/utils/types";

type DetailsProps<T extends Codec = Codec> = {
  name: string;
  data: T;
  overrideRawType?: string;
};
const Account32Details: React.FC<DetailsProps<AccountId32>> = (props) => {
  const { name, data, overrideRawType } = props;

  return (
    <DashedBordered>
      <Typography variant="body2">
        {name}: {overrideRawType || data.toRawType()}
      </Typography>
      <Typography variant="body2">{data.toString()}</Typography>
    </DashedBordered>
  );
};

const U128Details: React.FC<DetailsProps<u128> & { api: ApiRx }> = (props) => {
  const { api, name, data, overrideRawType } = props;

  return (
    <DashedBordered>
      <Typography variant="body2">
        {name}: {overrideRawType || data.toRawType()}
      </Typography>
      <Typography variant="body2">
        {toUnit(
          data,
          api.registry.chainDecimals[0],
          api.registry.chainTokens[0]
        )}
      </Typography>
    </DashedBordered>
  );
};

const FrameSupportWeightsDispatchInfoDetails: React.FC<
  DetailsProps<FrameSupportWeightsDispatchInfo>
> = (props) => {
  const { name, data, overrideRawType } = props;
  return (
    <DashedBordered>
      <Typography variant="body2">
        {name}: {overrideRawType || data.toRawType()}
      </Typography>
      <pre>{JSON.stringify(data.toJSON(), null, 2)}</pre>
    </DashedBordered>
  );
};

const VestingScheduleLinearLinearScheduleDetails: React.FC<
  DetailsProps<Vec<VestingScheduleLinearLinearSchedule>>
> = (props) => {
  const { name, data, overrideRawType } = props;

  return (
    <>
      <Typography variant="body2">
        {name}: {overrideRawType || data.toRawType()}
      </Typography>
      <TabComponent>
        <Stack gap={2}>
          {data.map((oneData, index) => (
            <Box key={oneData.hash.toString()}>
              <DashedBordered>
                <Typography variant="body2">
                  {index}: {"VestingScheduleLinearLinearSchedule"}
                </Typography>
                <pre>
                  {JSON.stringify(
                    {
                      balance: oneData.balance.toHuman(),
                      cliff: oneData.cliff.toHuman(),
                      vesting: oneData.vesting.toHuman(),
                    },
                    null,
                    2
                  )}
                </pre>
              </DashedBordered>
            </Box>
          ))}
        </Stack>
      </TabComponent>
    </>
  );
};

const PrimitivesEthereumEthereumAddressDetails: React.FC<
  DetailsProps<Codec>
> = (props) => {
  const { name, data, overrideRawType } = props;

  return (
    <DashedBordered>
      <Typography variant="body2">
        {name}: {overrideRawType || data.toRawType()}
      </Typography>
      <Typography variant="body2">{data.toString()}</Typography>
    </DashedBordered>
  );
};

const ExtrinsicFailedDetails: React.FC<
  Omit<DetailsProps<ExtrinsicFailedType>, "name"> & { api: ApiRx }
> = (props) => {
  const { data, api } = props;

  const mod = data.dispatchError.asModule;
  const { docs, section, method } = api.registry.findMetaError(mod);

  return (
    <TabComponent>
      <Stack gap={2}>
        <DashedBordered>
          <Typography variant="body2">
            dispatchError: SpRuntimeDispatchError
          </Typography>
          <pre>
            {JSON.stringify(
              {
                index: data.dispatchError.asModule.index,
                error: data.dispatchError.asModule.error,
              },
              null,
              2
            )}
          </pre>
        </DashedBordered>
        <DashedBordered>
          <Typography variant="body2">type</Typography>
          <Typography variant="body2">
            {section}.{method}
          </Typography>
        </DashedBordered>
        <DashedBordered>
          <Typography variant="body2">details</Typography>
          <Typography variant="body2">{docs}</Typography>
        </DashedBordered>
        <FrameSupportWeightsDispatchInfoDetails
          data={data.dispatchInfo}
          name="dispatchInfo"
          overrideRawType="FrameSupportWeightsDispatchInfo"
        />
      </Stack>
    </TabComponent>
  );
};

type ClaimingEventProps = {
  eventData: EventRecord;
};
const ClaimingEvent: React.FC<ClaimingEventProps> = (props) => {
  const { api } = useApi();
  const { eventData } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const expandHandler = useCallback(
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded);
    },
    []
  );

  const docs = useMemo(
    () => eventData.event.meta.docs.toHuman(),
    [eventData.event.meta.docs]
  );
  const type = useMemo(
    () => `${eventData.event.section}.${eventData.event.method}`,
    [eventData.event.method, eventData.event.section]
  );

  const details = useMemo(() => {
    if (type === "system.ExtrinsicFailed") {
      return (
        <ExtrinsicFailedDetails
          data={eventData.event.data as unknown as ExtrinsicFailedType}
          api={api}
        />
      );
    }
    if (type === "system.ExtrinsicSuccess") {
      return (
        <FrameSupportWeightsDispatchInfoDetails
          data={(eventData.event.data as unknown as ExtrinsicType).dispatchInfo}
          name={eventData.event.data.names?.[0]!}
          overrideRawType={"FrameSupportWeightsDispatchInfo"}
        />
      );
    }
    return eventData.event.data.map((data, index) => {
      const name = eventData.event.data.names?.[index];

      if (!name) return null;

      switch (data.toRawType()) {
        case "AccountId":
          return (
            <Account32Details
              key={data.toRawType() + index}
              data={data as AccountId32}
              name={name}
              overrideRawType="Account32"
            />
          );
        case "[u8;20]":
          return (
            <Account32Details
              key={data.toRawType() + index}
              data={data as AccountId32}
              name={name}
              overrideRawType="PrimitivesEthereumEthereumAddress ([u8;20])"
            />
          );
        case "u128":
          return (
            <U128Details
              key={data.toRawType() + index}
              data={data as u128}
              name={name}
              api={api}
            />
          );
        case "FrameSupportWeightsDispatchInfo":
          return (
            <FrameSupportWeightsDispatchInfoDetails
              key={data.toRawType() + index}
              data={data as FrameSupportWeightsDispatchInfo}
              name={name}
            />
          );
        case "Vec<VestingScheduleLinearLinearSchedule>":
          return (
            <VestingScheduleLinearLinearScheduleDetails
              key={data.toRawType() + index}
              data={data as Vec<VestingScheduleLinearLinearSchedule>}
              name={name}
            />
          );
        case "PrimitivesEthereumEthereumAddress":
          return (
            <PrimitivesEthereumEthereumAddressDetails
              key={data.toRawType() + index}
              data={data as DispatchInfo}
              name={name}
            />
          );
        default:
          return null;
      }
    });
  }, [api, eventData.event.data, type]);

  return (
    <Accordion expanded={expanded} onChange={expandHandler}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="content"
        id="header"
      >
        <Stack>
          <Typography variant="body1">{type}</Typography>
          <Typography variant="body2">{docs?.toString()}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2}>{details}</Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const ClaimingEventsView: React.FC = () => {
  const {
    values: { claimingEvents },
  } = useFormikContext<FormValues>();
  const { linkToExplorer } = useConfig();

  return !claimingEvents.length ? (
    <Stack>
      <Alert severity="info">Waiting for claiming events...</Alert>
      <Alert severity="warning">
        <Typography>
          If the loader gets stuck on this step, go directly to{" "}
          <Link target="_blank" rel="noreferrer" href={linkToExplorer}>
            Polkadot apps
          </Link>
          .
        </Typography>
        <Typography>
          Most likely, your tokens were claimed successfully and now you can
          unlock them:{" "}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://link.humanode.io/guide/token-claims"
          >
            Guide
          </Link>
          .
        </Typography>
      </Alert>
      <Skeleton animation="wave" width="100%">
        <Paper />
      </Skeleton>
    </Stack>
  ) : (
    <>
      {claimingEvents.map((eventData, index) => (
        <ClaimingEvent
          key={index + String(eventData.hash)}
          eventData={eventData}
        />
      ))}
    </>
  );
};

export default ClaimingEventsView;
