import type { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { useEffect, useMemo, useState } from "react";
import type { AddEthereumChainParameter } from "../../eips";

export enum NETWORK {
  REQUIRED,
  OTHER,
}

const useRequiredNetwork = (
  provider: Web3Provider,
  addEthereumChainParameter: AddEthereumChainParameter
) => {
  const [requiredNetwork, setRequiredNetwork] = useState<NETWORK>(
    NETWORK.OTHER
  );
  const rpcProvider = useMemo(
    () => provider.provider as unknown as JsonRpcProvider,
    [provider.provider]
  );

  useEffect(() => {
    const onNetworkChanged = (ethChainId: string) => {
      const network =
        parseInt(addEthereumChainParameter.chainId) === Number(ethChainId)
          ? NETWORK.REQUIRED
          : NETWORK.OTHER;

      setRequiredNetwork(network);
    };

    rpcProvider.on("chainChanged", onNetworkChanged);
    provider.send("eth_chainId", []).then(onNetworkChanged);

    return () => {
      rpcProvider.removeListener("chainChanged", onNetworkChanged);
    };
  }, [addEthereumChainParameter, provider, rpcProvider]);

  return requiredNetwork;
};

export default useRequiredNetwork;
