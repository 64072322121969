import { useEffect } from "react";
import { useConfig } from "../subsystem/config/state";
import * as typedLocalStorage from "../subsystem/localStorage";

const useSetupDefaultConnectionUrl = () => {
  const { baseUrl } = useConfig();

  useEffect(() => {
    const connectionUrl = typedLocalStorage.getItem("connectionUrl");
    if (!connectionUrl) {
      typedLocalStorage.setItem("connectionUrl", baseUrl);
    }
  }, [baseUrl]);

  return null;
};

export default useSetupDefaultConnectionUrl;
