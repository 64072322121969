import {
  Alert,
  Box,
  Stack,
  StepContent,
  StepLabel,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { PropsWithChildren, useCallback, useMemo } from "react";
import Connector from "../lib/ui/Connector";
import Section from "../lib/ui/Section";
import EthereumConnect from "../lib/wallet/ethereum/connect";
import { getWeb3Provider } from "../lib/wallet/ethereum/provider";
import { useConfig } from "../subsystem/config/state";
import textsOnPage from "../textsOnPage";
import Eip712ManualSignTab from "./Eip712ManualSignTab";
import Eip712Web3WalletSignTab from "./Eip712Web3WalletSignTab";
import Eip191ManualSignTab from "./Eip191ManualSignTab";
import type { FormValues } from "./Form";
import type { StepData } from "./Steps";

const STEP_INDEX = 1;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ mt: 2 }}>{children}</Box>
    </div>
  );
}

const SetupEthereum: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { addEthereumChainParameter } = useConfig();

  const isExtMissing = useMemo(() => !window.ethereum, []);

  const provider = useMemo(
    () => !isExtMissing && getWeb3Provider(),
    [isExtMissing]
  );

  if (!provider) {
    return <Typography variant="body1">Ethereum extension missing</Typography>;
  }

  return (
    <EthereumConnect
      addEthereumChainParameter={addEthereumChainParameter}
      provider={provider}
    >
      {children}
    </EthereumConnect>
  );
};

const Label: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <StepLabel
      StepIconProps={{
        style: {
          color:
            values.formParams.activeStep === STEP_INDEX ? "#90caf9" : "gray",
        },
      }}
      sx={{ pr: 4, pb: 1 }}
    >
      {textsOnPage.form.steps[STEP_INDEX].defaultStepLabel}
    </StepLabel>
  );
};

const Completed: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  if (values.formParams.activeStep < STEP_INDEX + 1) return null;

  return (
    <Stack sx={{ mb: 1 }}>
      <Alert severity="info">
        {
          textsOnPage.form.steps[STEP_INDEX].completed.description?.[
            values.formParams.tabIndex
          ]
        }
      </Alert>
      <Section value={values.ethereum.address} />
    </Stack>
  );
};

const Main: React.FC = () => {
  const {
    values: {
      formParams: { tabIndex },
    },
    setFieldValue,
  } = useFormikContext<FormValues>();

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setFieldValue("formParams.tabIndex", newValue);
    },
    [setFieldValue]
  );

  return (
    <StepContent>
      <Stack>
        {textsOnPage.form.steps[STEP_INDEX].descriptions?.map((description) => (
          <Alert key={description} severity="info">
            {description}
          </Alert>
        ))}
      </Stack>
      <Tabs value={tabIndex} onChange={handleChange}>
        <Tab
          label={
            <Typography>
              {textsOnPage.form.steps[STEP_INDEX].tabs?.[0].name}
            </Typography>
          }
        />
        <Tab
          label={
            <Typography>
              {textsOnPage.form.steps[STEP_INDEX].tabs?.[1].name}
            </Typography>
          }
        />
        <Tab
          label={
            <Typography>
              {textsOnPage.form.steps[STEP_INDEX].tabs?.[2].name}
            </Typography>
          }
        />
      </Tabs>
      <Connector>
        <Box pl={1}>
          <TabPanel value={tabIndex} index={0}>
            <SetupEthereum>
              <Eip712Web3WalletSignTab />
            </SetupEthereum>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <Eip712ManualSignTab />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <Eip191ManualSignTab />
          </TabPanel>
        </Box>
      </Connector>
    </StepContent>
  );
};

const EthereumStep: StepData = {
  main: Main,
  completed: Completed,
  label: Label,
};

export default EthereumStep;
