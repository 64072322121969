import type { EIP712Domain } from "eip-712";
import { loadProduction } from "hybridconfig";

const baseUrlFormat = (url: string) => {
  return new URL(url).origin;
};

type AllowedKeys =
  | "baseUrl"
  | "addEthereumChainParameter"
  | "eip712domain"
  | "eip191params"
  | "environment"
  | "linkToExplorer";

export interface AddEthereumChainParameter {
  chainId: string;
  blockExplorerUrls?: string[];
  chainName?: string;
  iconUrls?: string[];
  nativeCurrency?: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls?: string[];
}

const config = loadProduction<AllowedKeys>({
  remoteConfigPath: "/config.json",
  envKeyPrefix: "REACT_APP_",
}).then((config) => {
  const {
    baseUrl,
    addEthereumChainParameter,
    eip712domain,
    eip191params,
    ...rest
  } = config;
  const addEthereumChainParameterJSON: AddEthereumChainParameter = JSON.parse(
    addEthereumChainParameter
  );
  const eip712domainJSON: EIP712Domain = JSON.parse(eip712domain);
  const eip191paramsJSON: { format: string } = JSON.parse(eip191params);

  return {
    baseUrl: baseUrlFormat(baseUrl),
    addEthereumChainParameter: addEthereumChainParameterJSON,
    eip712domain: eip712domainJSON,
    eip191params: eip191paramsJSON,
    ...rest,
  };
});

export default config;
