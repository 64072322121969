type HexString = `0x${string}`;

export type BuildMessageParams = {
  format: string;
  substrateAddress: HexString;
  genesisHash: HexString;
};

export type Message = string;

const formatMessage = (fmt: string, args: { [key: string]: string }) => {
  for (const key in args) {
    const val = args[key];
    if (!val) {
      throw new Error(
        `unknow format argument "${key}" in the format string "${fmt}"`
      );
    }
    fmt = fmt.replace("{" + key + "}", val);
  }
  return fmt;
};

export const buildMessage = ({
  format,
  substrateAddress,
  genesisHash,
}: BuildMessageParams): Message =>
  formatMessage(format, {
    substrate_address: substrateAddress,
    genesis_hash: genesisHash,
  });
