import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React from "react";
import AppErrorsSnackbar from "./components/AppErrorsSnackbar";
import Entrypoint from "./routes/Entrypoint";
import theme from "./theme";

const App: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <AppErrorsSnackbar />
          <Entrypoint />
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
