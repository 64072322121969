import type { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import type { EventRecord } from "@polkadot/types/interfaces/system";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { Observable } from "rxjs";
import { substrateAccountsSubscribe } from "./provider";

type SubstrateState = {
  substrateAccounts: InjectedAccountWithMeta[];
  substrateSign: () => Observable<EventRecord[]>;
};

export const SubstrateContext = createContext<SubstrateState>(null!);

type SubstrateConnectProps = {
  ss58Format?: number;
  substrateSign: () => Observable<EventRecord[]>;
} & PropsWithChildren;
const SubstrateConnect: React.FC<SubstrateConnectProps> = (props) => {
  const { children, ss58Format, substrateSign } = props;
  const [substrateAccounts, setSubstrateAccounts] = useState<
    InjectedAccountWithMeta[]
  >([]);

  useEffect(() => {
    let unsub: () => void = () => {};

    substrateAccountsSubscribe(async (accounts) => {
      setSubstrateAccounts(accounts);
    }, ss58Format).then((_unsub) => {
      unsub = _unsub;
    });

    return () => unsub();
  }, [ss58Format]);

  const substrateState = useMemo(
    () => ({
      substrateAccounts,
      substrateSign,
    }),
    [substrateAccounts, substrateSign]
  );

  return (
    <SubstrateContext.Provider value={substrateState} children={children} />
  );
};

export default SubstrateConnect;
