import { Box, Typography } from "@mui/material";
import Paper from "./Paper";

type SectionProps = {
  value?: string;
  label?: string;
  mono?: true;
  maxLines?: number;
  rightAction?: React.ReactElement;
};
const Section: React.FC<SectionProps> = (props) => {
  const { value, label, rightAction, mono, maxLines } = props;
  const valueExt = {
    ...(mono && { fontFamily: "monospace" }),
    ...(maxLines && {
      maxHeight: `${maxLines * 1.5 /* line height */}rem`,
      overflow: "scroll",
    }),
  };
  return (
    <Paper>
      {label && (
        <div
          style={{
            position: "absolute",
            top: "-9px",
          }}
        >
          <Typography color="#a0a0a0" fontSize="0.75rem">
            {label}
          </Typography>
        </div>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          color="#a0a0a0"
          alignSelf="center"
          sx={{ wordBreak: "break-word" }}
          whiteSpace="pre-wrap"
          {...valueExt}
        >
          {value}
        </Typography>
        {rightAction}
      </Box>
    </Paper>
  );
};

export default Section;
