import { PropsWithChildren, useEffect, useState } from "react";
import type { InjectedExtension } from "@polkadot/extension-inject/types";
import { RequirementsContext, State } from "./state";

type RequirementsInitProps = PropsWithChildren & {
  substrateEnable: () => Promise<InjectedExtension | undefined>;
};
const RequirementsInit: React.FC<RequirementsInitProps> = (props) => {
  const { children, substrateEnable } = props;
  const [value, setValue] = useState<State>({
    tag: "uninit",
    data: {},
  });

  useEffect(() => {
    substrateEnable().then((extension) => {
      if (extension) {
        setValue({ tag: "ready", data: { extension } });
        return;
      }
      setValue({ tag: "substrateExtensionMissing", data: {} });
    });
  }, [substrateEnable]);

  return <RequirementsContext.Provider value={value} children={children} />;
};

export default RequirementsInit;
