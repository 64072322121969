import { Alert, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import type { ApiRx } from "@polkadot/api";
import { isHexString } from "ethers/lib/utils";
import { FormikHelpers } from "formik";
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import ApiConnectionSettingLayer from "../components/ApiConnectionSettingLayer";
import FormComponent, { FormValues } from "../components/Form";
import useSetupDefaultConnectionUrl from "../hooks/useSetupDefaultConnectionUrl";
import { substrateEnable } from "../lib/wallet/substrate/provider";
import ApiInit from "../subsystem/api/ApiInit";
import { ApiGuard, useApi } from "../subsystem/api/state";
import { createApi, createProvider } from "../subsystem/humanodePeerApi/api";
import useSyncState, {
  SyncStateGuard,
} from "../subsystem/humanodePeerApi/rpcControllers/useSyncState";
import { systemHealth } from "../subsystem/humanodePeerApi/wrappers";
import * as typedLocalStorage from "../subsystem/localStorage";
import RequirementsInit from "../subsystem/requirements/RequirementsInit";
import { RequirementsGuard } from "../subsystem/requirements/state";
import textsOnPage from "../textsOnPage";
import ErrorPage from "./ErrorPage";
import Layout from "./Layout";

type HexString = `0x${string}`;

const IsNotSupportedPeer: React.FC = () => (
  <Layout logo>
    <ApiConnectionSettingLayer>
      <Stack sx={{ gap: 2 }}>
        <Typography align="center" variant="h5">
          Established connection to the peer.
        </Typography>
        <Alert severity="warning" variant="outlined">
          Peer doesn't have a suitable API, please choose the supported peer.
        </Alert>
      </Stack>
    </ApiConnectionSettingLayer>
  </Layout>
);

const RequirementsInitializing: React.FC = () => (
  <Layout logo>
    <CircularProgress />
    <Typography variant="body1">Checking requirements</Typography>
  </Layout>
);

const SubstrateExtensionMissing: React.FC = () => (
  <Layout logo>
    <Typography variant="body1">Polkadot.js extension missing</Typography>
  </Layout>
);

const ApiLoadingPage: React.FC = () => (
  <Layout logo>
    <ApiConnectionSettingLayer>
      <CircularProgress />
      <Typography variant="body1">Initializing api connection</Typography>
    </ApiConnectionSettingLayer>
  </Layout>
);

const ApiEstablishingPage: React.FC = () => (
  <Layout logo>
    <ApiConnectionSettingLayer>
      <CircularProgress />
      <Typography variant="body1">Connecting to the peer</Typography>
      <Typography variant="body2">
        Waiting to make a connection to the remote endpoint and finishing API
        initialization.
      </Typography>
      <Typography variant="body2">
        Ensure that your node is running, synced and that the Websocket endpoint
        is reachable.
      </Typography>
    </ApiConnectionSettingLayer>
  </Layout>
);

const ApiDisconnectedPage: React.FC = () => (
  <Layout logo>
    <ApiConnectionSettingLayer>
      <CircularProgress />
      <Typography variant="body1">Lost connection to the peer</Typography>
      <Typography variant="body2">
        You are not connected to a node. Ensure that your node is running and
        that the Websocket endpoint is reachable.
      </Typography>
    </ApiConnectionSettingLayer>
  </Layout>
);

const Requirements: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const ready = useMemo(() => <>{children}</>, [children]);

  return (
    <RequirementsInit substrateEnable={substrateEnable}>
      <RequirementsGuard
        uninit={<RequirementsInitializing />}
        substrateExtensionMissing={<SubstrateExtensionMissing />}
        ready={ready}
      />
    </RequirementsInit>
  );
};

const ApiConnector: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [connectionUrl, setConnectionUrl] = useState<string | null>(
    typedLocalStorage.getItem("connectionUrl")
  );

  const handleSetConnectionUrl = useCallback((newConnectionUrl: string) => {
    if (!newConnectionUrl) return;

    typedLocalStorage.setItem("connectionUrl", newConnectionUrl);
    setConnectionUrl(newConnectionUrl);
  }, []);

  const builder = useCallback(() => {
    if (!connectionUrl) throw new Error("connection url is not set");

    const provider = createProvider(connectionUrl);
    const api = createApi({ provider, throwOnConnect: true });

    return { provider, api };
  }, [connectionUrl]);

  const ready = useCallback(() => <>{children}</>, [children]);

  return (
    <ApiInit
      key={connectionUrl}
      setConnectionUrl={handleSetConnectionUrl}
      builder={builder}
    >
      <ApiGuard
        uninit={ApiLoadingPage}
        establishing={ApiEstablishingPage}
        disconnected={ApiDisconnectedPage}
        ready={ready}
        error={ErrorPage}
      />
    </ApiInit>
  );
};

const isSupportedPeerVersion = (api: ApiRx): boolean =>
  api.tx.tokenClaims !== undefined;

const CheckPeerVersion: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { api } = useApi();
  const supportedPeerVersion = useMemo(
    () => isSupportedPeerVersion(api),
    [api]
  );

  const synced = useCallback(() => <>{children}</>, [children]);

  const systemHealthHandler = useCallback(() => systemHealth(api), [api]);

  useSyncState({
    systemHealth: systemHealthHandler,
  });

  return supportedPeerVersion ? (
    <SyncStateGuard
      uninit={ApiEstablishingPage}
      syncing={ApiEstablishingPage}
      synced={synced}
      error={ErrorPage}
    />
  ) : (
    <IsNotSupportedPeer />
  );
};

const CommonBoundary: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  useSetupDefaultConnectionUrl();

  return (
    <Requirements>
      <ApiConnector>
        <CheckPeerVersion>{children}</CheckPeerVersion>
      </ApiConnector>
    </Requirements>
  );
};

const initialFormValues: FormValues = {
  ethereum: {},
  substrate: {},
  manual: {},
  eip712: {},
  claimingEvents: [],
  nothingToClaim: false,
  formParams: {
    activeStep: 0,
    complete: false,
    showLogs: false,
    tabIndex: window.ethereum ? 0 : 1,
  },
};

const validate = (values: FormValues) => {
  const {
    manual,
    eip712,
    formParams: { tabIndex },
  } = values;
  const requiredHexFormat = (value?: string | HexString, length?: number) =>
    value && !isHexString(value, length);

  if (tabIndex === 1) {
    if (requiredHexFormat(eip712.ethereumAddress, 20)) {
      return {
        eip712: {
          ethereumAddress: textsOnPage.form.validation.eip712.ethereumAddress,
        },
      };
    }
    if (requiredHexFormat(eip712.signature, 65)) {
      return {
        eip712: { signature: textsOnPage.form.validation.eip712.signature },
      };
    }
  }
  if (tabIndex === 2) {
    if (requiredHexFormat(manual.ethereumAddress, 20)) {
      return {
        manual: {
          ethereumAddress: textsOnPage.form.validation.manual.ethereumAddress,
        },
      };
    }
    if (requiredHexFormat(manual.signature, 65)) {
      return {
        manual: { signature: textsOnPage.form.validation.manual.signature },
      };
    }
  }
};

const onSubmit = async (
  values: FormValues,
  { setFieldValue }: FormikHelpers<FormValues>
) => {
  if (values.formParams.activeStep >= 4) return;

  setFieldValue("formParams.activeStep", values.formParams.activeStep + 1);
};

const FormPage: React.FC = () => (
  <CommonBoundary>
    <FormComponent
      initialFormValues={initialFormValues}
      onSubmit={onSubmit}
      validate={validate}
    />
  </CommonBoundary>
);

export default FormPage;
