import { TextField } from "@mui/material";

type FieldViewOnlyProps = {
  label?: string;
  formikPath: string;
  value: string;
};
const FieldViewOnly: React.FC<FieldViewOnlyProps> = (props) => (
  <TextField
    fullWidth
    label={props.label}
    variant="outlined"
    disabled
    sx={{ mt: 1 }}
    id={props.formikPath}
    name={props.formikPath}
    value={props.value}
  />
);

export default FieldViewOnly;
