import type { JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { useEffect, useMemo, useState } from "react";

type HexString = `0x${string}`;

const useEthereumAddresses = (provider: Web3Provider) => {
  const [ethereumAddress, setEthereumAddress] = useState<HexString>();
  const rpcProvider = useMemo(
    () => provider.provider as unknown as JsonRpcProvider,
    [provider.provider]
  );

  useEffect(() => {
    const setFirstAccount = (addresses: Array<string>) =>
      setEthereumAddress(addresses[0] as HexString);

    provider
      .send("eth_requestAccounts", [])
      .then(setFirstAccount)
      .catch((error: Error) => {
        console.error(error);
      });

    rpcProvider.on("accountsChanged", setFirstAccount);

    return () => {
      rpcProvider.removeListener("accountsChanged", setFirstAccount);
    };
  }, [provider, rpcProvider]);

  return ethereumAddress;
};

export default useEthereumAddresses;
