import { Stack, Stepper } from "@mui/material";
import type { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import { getSpecTypes } from "@polkadot/types-known";
import type { EventRecord } from "@polkadot/types/interfaces/system";
import { base64Encode } from "@polkadot/util-crypto";
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { useCallback, useEffect, useState } from "react";
import { scan, takeWhile } from "rxjs";
import Section from "../lib/ui/Section";
import SubstrateConnect from "../lib/wallet/substrate/connect";
import { substrateUpdate } from "../lib/wallet/substrate/provider";
import Layout from "../pages/Layout";
import { useApi } from "../subsystem/api/state";
import {
  getClaims,
  getSystemChain,
  txTokenClaimsClaim,
} from "../subsystem/humanodePeerApi/wrappers";
import { useSubstrateInjectedExtension } from "../subsystem/requirements/state";
import TokenClaimsInit from "../subsystem/tokenClaims/TokenClaimsInit";
import ApiConnectionSettingLayer from "./ApiConnectionSettingLayer";
import Steps from "./Steps";

type HexString = `0x${string}`;

export type FormValues = {
  ethereum: {
    address?: HexString;
  };
  substrate: {
    account?: InjectedAccountWithMeta;
    hex?: HexString;
  };
  manual: {
    ethereumAddress?: HexString;
    signature?: HexString;
  };
  eip712: {
    ethereumAddress?: HexString;
    signature?: HexString;
  };
  signature?: HexString;
  nothingToClaim: boolean;
  claimingEvents: EventRecord[];
  formParams: {
    activeStep: number;
    tabIndex: number;
    complete: boolean;
    showLogs: boolean;
  };
  claimButton?: {};
};

const FormBody: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { api } = useApi();
  const [systemChain, setSystemChain] = useState("");
  const { extension } = useSubstrateInjectedExtension();

  const substrateSign = useCallback(
    () =>
      txTokenClaimsClaim(
        api,
        values.substrate.account!,
        values.ethereum.address!,
        values.signature!
      ).pipe(
        scan((acc, one) => {
          const events = acc.concat(one.events);
          setFieldValue("claimingEvents", events);

          return events;
        }, [] as EventRecord[]),
        takeWhile(
          (eventRecords) =>
            eventRecords.every((eventRecord) => {
              return !["ExtrinsicFailed", "ExtrinsicSuccess"].includes(
                eventRecord.event.method
              );
            }),
          true
        )
      ),
    [
      api,
      setFieldValue,
      values.ethereum.address,
      values.signature,
      values.substrate.account,
    ]
  );

  useEffect(() => {
    getSystemChain(api).then((systemChain) => {
      setSystemChain(systemChain);
      substrateUpdate(api, extension, {
        chain: systemChain,
        chainType: "substrate",
        genesisHash: api.genesisHash.toHex(),
        metaCalls: base64Encode(api.runtimeMetadata.asCallsOnly.toU8a()),
        specVersion: api.runtimeVersion.specVersion.toNumber(),
        ss58Format: Number(api.registry.chainSS58),
        tokenDecimals: api.registry.chainDecimals[0],
        tokenSymbol: api.registry.chainTokens[0],
        types: getSpecTypes(
          api.registry,
          systemChain,
          api.runtimeVersion.specName,
          api.runtimeVersion.specVersion
        ) as unknown as Record<string, string>,
        icon: "",
      });
    });
  }, [api, extension]);

  const getClaimsHandler = useCallback(
    (address: HexString) => getClaims(api, address),
    [api]
  );

  return (
    <TokenClaimsInit
      ethereumAddress={values.ethereum.address}
      getClaims={getClaimsHandler}
    >
      <SubstrateConnect
        ss58Format={api.runtimeChain.registry.chainSS58}
        substrateSign={substrateSign}
      >
        <Form>
          <Stack pt={3}>
            <Section label="Chain name" value={systemChain} />
            <Stepper
              activeStep={values.formParams.activeStep}
              orientation="vertical"
              connector={null}
              sx={{ width: "100%", minWidth: "700px", maxWidth: "700px" }}
            >
              {Steps}
            </Stepper>
          </Stack>
        </Form>
      </SubstrateConnect>
    </TokenClaimsInit>
  );
};

type FormComponentProps = {
  initialFormValues: FormValues;
  validate: (values: FormValues) => FormikErrors<FormValues> | undefined;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => Promise<void>;
};
const FormComponent: React.FC<FormComponentProps> = (props) => {
  return (
    <Layout>
      <ApiConnectionSettingLayer>
        <Formik<FormValues>
          initialValues={props.initialFormValues}
          validate={props.validate}
          validateOnChange
          onSubmit={props.onSubmit}
        >
          <FormBody />
        </Formik>
      </ApiConnectionSettingLayer>
    </Layout>
  );
};

export default FormComponent;
