import type { Web3Provider } from "@ethersproject/providers";
import { createContext, PropsWithChildren, useCallback, useMemo } from "react";
import type { AddEthereumChainParameter } from "../eips";
import useEthereumAddresses from "./hooks/useEthereumAddresses";
import useRequiredNetwork, { NETWORK } from "./hooks/useRequiredNetwork";
import {
  ethereumSign,
  EthereumSignOptions,
  switchEthereumNetwork,
} from "./provider";

type HexString = `0x${string}`;

type EthereumContextType = {
  requiredNetwork: NETWORK;
  ethereumAddress?: HexString;
  sign: (options: EthereumSignOptions) => Promise<HexString>;
  switchNetwork: () => Promise<void>;
};

export const EthereumContext = createContext<EthereumContextType>(
  {} as EthereumContextType
);

type EthereumConnectProps = PropsWithChildren & {
  addEthereumChainParameter: AddEthereumChainParameter;
  provider: Web3Provider;
};
const EthereumConnect: React.FC<EthereumConnectProps> = (props) => {
  const { addEthereumChainParameter, provider, children } = props;

  const requiredNetwork = useRequiredNetwork(
    provider,
    addEthereumChainParameter
  );

  const ethereumAddress = useEthereumAddresses(provider);

  const sign = useCallback(
    (options: EthereumSignOptions) => ethereumSign(provider, options),
    [provider]
  );

  const switchNetwork = useCallback(
    async () =>
      await switchEthereumNetwork(provider, addEthereumChainParameter),
    [addEthereumChainParameter, provider]
  );

  const value = useMemo(
    () => ({
      ethereumAddress,
      requiredNetwork,
      sign,
      switchNetwork,
    }),
    [ethereumAddress, requiredNetwork, sign, switchNetwork]
  );

  return <EthereumContext.Provider value={value} children={children} />;
};

export default EthereumConnect;
