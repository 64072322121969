import type { ApiRx } from "@polkadot/api";
import type { SubmittableResultSubscription } from "@polkadot/api/types";
import { web3FromAddress } from "@polkadot/extension-dapp";
import type { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import type { Option } from "@polkadot/types";
import type { PalletTokenClaimsClaimInfo } from "@polkadot/types/lookup";
import { firstValueFrom, from, Observable, switchMap, catchError } from "rxjs";
import { addAppError } from "../../components/appErrors";

type HexString = `0x${string}`;

export const getClaims = (
  api: ApiRx,
  ethereumAddress: HexString
): Observable<Option<PalletTokenClaimsClaimInfo>> => {
  return api.query.tokenClaims.claims(ethereumAddress);
};

export const txTokenClaimsClaim = (
  api: ApiRx,
  substrateAccount: InjectedAccountWithMeta,
  ethereumAddress: HexString,
  signature: HexString
): SubmittableResultSubscription<"rxjs"> =>
  from(web3FromAddress(substrateAccount.address)).pipe(
    switchMap((injector) => {
      api.setSigner(injector.signer);

      return api.tx.tokenClaims
        .claim(ethereumAddress, signature)
        .signAndSend(substrateAccount.address);
    }),
    catchError((error, caught) => {
      addAppError(error);
      return caught;
    })
  );

export interface HealthJSON {
  isSyncing: {
    isTrue: boolean;
  };
}
export const systemHealth = (api: ApiRx): Promise<HealthJSON> =>
  firstValueFrom(api.rpc.system.health()).then((health) => ({
    isSyncing: { isTrue: health.isSyncing.isTrue },
  }));

export const getSystemChain = (api: ApiRx): Promise<string> =>
  firstValueFrom(api.rpc.system.chain()).then((text) => text.toString());
