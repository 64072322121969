import styled from "@emotion/styled";

const Connector = styled("div")({
  marginLeft: "12px",
  paddingLeft: "20px",
  paddingRight: "10px",
  borderLeft: "1px solid #757575",
  minHeight: "24px",
});

export default Connector;
