import type { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";
import type { InjectedExtension } from "@polkadot/extension-inject/types";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"substrateExtensionMissing">
  | StateVariant<"ready", { extension: InjectedExtension }>;

const { Context, ElementGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const RequirementsContext = Context;
export const RequirementsGuard = ElementGuard;

export const useSubstrateInjectedExtension = createTaggedStateHook(
  Context,
  "ready" as const
);
