import type { u128 } from "@polkadot/types-codec";
import type { Balance } from "@polkadot/types/interfaces";
import { formatBalance } from "@polkadot/util";

export const toUnit = (
  balance: u128 | Balance,
  decimals: number,
  withUnit: string
) => {
  const unitString = formatBalance(balance, {
    decimals,
    withUnit,
    withSi: true,
    forceUnit: "Unit",
  });

  return unitString.substring(0, unitString.indexOf(withUnit));
};

export const msToString = (from: number, to: number) => {
  const dateFrom = new Date(from);
  const dateTo = new Date(to);

  let month, day, hour, minute, second;

  second = dateTo.getUTCSeconds() - dateFrom.getUTCSeconds();
  minute = dateTo.getUTCMinutes() - dateFrom.getUTCMinutes();
  hour = dateTo.getUTCHours() - dateFrom.getUTCHours();
  day = dateTo.getUTCDate() - dateFrom.getUTCDate();
  month =
    dateTo.getUTCMonth() -
    dateFrom.getUTCMonth() +
    12 * (dateTo.getUTCFullYear() - dateFrom.getUTCFullYear());

  const values: Record<string, number> = {
    month,
    day,
    hour,
    minute,
    second,
  };

  const toString = (key: string) => {
    const value = values[key];

    return value > 0 ? `${value} ${key}${value === 1 ? "" : "s"}` : "";
  };

  return Object.keys(values)
    .reduce((prev, key) => {
      const curSrt = toString(key);

      if (key === "second" && !prev.trim()) {
        return "0 seconds";
      }

      return prev.concat(curSrt + " ");
    }, "")
    .trim();
};
