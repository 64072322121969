import { ApiRx, HttpProvider, WsProvider } from "@polkadot/api";
import type { ApiOptions } from "@polkadot/api/types";
import type { ProviderInterface } from "@polkadot/rpc-provider/types";
import type { Observable } from "rxjs";
import * as definitions from "../../interfaces/generated/definitions";

export const createProvider = (url: string): ProviderInterface => {
  if (url.startsWith("ws://") || url.startsWith("wss://")) {
    return new WsProvider(url);
  }
  return new HttpProvider(url);
};

export const createApi = (
  options: Pick<ApiOptions, "provider" | "throwOnConnect">
): Observable<ApiRx> => {
  const types = Object.values(definitions).reduce(
    (res, { types }) => ({ ...res, ...types }),
    {}
  );
  const rpc = Object.entries(definitions).reduce(
    (res, [name, { rpc }]) => ({ ...res, [name]: rpc }),
    {}
  );

  try {
    return ApiRx.create({
      ...options,
      types,
      rpc,
    });
  } catch (error) {
    if (error instanceof Event) {
      if (error.target instanceof WebSocket) {
        throw new Error(`Unable to connect to ${error.target.url}`);
      }

      throw new Error(
        `Unexpected error event target ${JSON.stringify(error.target)}`
      );
    }

    throw error;
  }
};
