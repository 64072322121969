import type { ProviderInterface } from "@polkadot/rpc-provider/types";
import type { StateProps } from "@tagged-state/core";
import React, { useEffect, useRef, useState } from "react";
import { ApiContext, State } from "./state";

type Props = {
  children: React.ReactNode;
  setConnectionUrl: (connectionUrl: string) => void;
  builder: () => Omit<StateProps<State>["establishing"], "setConnectionUrl"> & {
    provider: ProviderInterface;
  };
};

const ApiInit: React.FC<Props> = (props) => {
  const { children, setConnectionUrl, builder } = props;

  const [value, setValue] = useState<State>({
    tag: "uninit",
    data: { setConnectionUrl },
  });
  const providerRef = useRef<ProviderInterface>(null!);

  useEffect(() => {
    const { provider, ...data } = builder();
    providerRef.current = provider;
    setValue({
      tag: "establishing",
      data: { api: data.api, setConnectionUrl },
    });

    let sub = data.api.subscribe({
      next: (api) =>
        setValue({ tag: "ready", data: { api, setConnectionUrl } }),
      error: (error) =>
        setValue({ tag: "error", data: { error, setConnectionUrl } }),
    });

    return () => {
      providerRef.current.disconnect();
      sub.unsubscribe();
    };
  }, [builder, setConnectionUrl]);

  useEffect(() => {
    const disconnectedHandler = () => {
      if (value.tag !== "ready") return;
      value.data.api.off("disconnected", disconnectedHandler);
      setValue({ tag: "disconnected", data: value.data });
    };
    const connectedHandler = () => {
      if (value.tag !== "disconnected") return;
      value.data.api.off("connected", connectedHandler);
      setValue({ tag: "ready", data: value.data });
    };

    if (value.tag === "ready") {
      value.data.api.on("disconnected", disconnectedHandler);
    } else if (value.tag === "disconnected") {
      value.data.api.on("connected", connectedHandler);
    }

    return () => {
      if (value.tag === "ready")
        value.data.api.off("disconnected", disconnectedHandler);
      if (value.tag === "disconnected")
        value.data.api.off("connected", connectedHandler);
    };
  }, [value.data, value.tag]);

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export default ApiInit;
