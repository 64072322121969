import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import Layout from "./Layout";

type Props = {
  children?: React.ReactNode;
  error: Error;
};

const ErrorDetails: React.FC<Props> = (props) => {
  const { error } = props;
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="button">Error details</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "block" }}>
        <Typography variant="body1" sx={{ fontFamily: "monospace" }}>
          {error.name}: {error.message}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const ErrorPage: React.FC<Props> = (props) => {
  const { error, children } = props;
  return (
    <Layout logo>
      <Container
        maxWidth="sm"
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        {children || (
          <Typography variant="h5" textAlign="center">
            Oops! An error has occured.
          </Typography>
        )}
        <ErrorDetails error={error} />
      </Container>
    </Layout>
  );
};

export default ErrorPage;
