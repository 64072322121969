import { Button, StepContent, StepLabel } from "@mui/material";
import { useFormikContext } from "formik";
import textsOnPage from "../textsOnPage";
import ClaimingEventsView from "./ClaimingEventsView";
import type { FormValues } from "./Form";
import { StepData } from "./Steps";

const STEP_INDEX = 3;

const Label: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <StepLabel
      StepIconProps={{
        style: {
          color:
            values.formParams.activeStep === STEP_INDEX ? "#90caf9" : "gray",
        },
      }}
      sx={{ pr: 4, pb: 1 }}
    >
      {textsOnPage.form.steps[STEP_INDEX].defaultStepLabel}
    </StepLabel>
  );
};

const Completed: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  if (values.formParams.activeStep <= STEP_INDEX) return null;

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        onClick={() =>
          setFieldValue("formParams.showLogs", !values.formParams.showLogs)
        }
        sx={{ mb: 1 }}
      >
        {values.formParams.showLogs
          ? textsOnPage.form.steps[STEP_INDEX]?.completed.toggleText?.[1]
          : textsOnPage.form.steps[STEP_INDEX]?.completed.toggleText?.[0]}
      </Button>
      {values.formParams.showLogs && <ClaimingEventsView />}
    </>
  );
};

const Main: React.FC = () => (
  <StepContent>
    <ClaimingEventsView />
  </StepContent>
);

const TransactionProcessingStep: StepData = {
  main: Main,
  completed: Completed,
  label: Label,
};

export default TransactionProcessingStep;
