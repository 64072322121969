import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  StepContent,
  StepLabel,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useFormikContext } from "formik";
import { useCallback, useContext } from "react";
import FieldViewOnly from "../lib/ui/FieldViewOnly";
import Section from "../lib/ui/Section";
import { SubstrateContext } from "../lib/wallet/substrate/connect";
import { addressToHex } from "../lib/wallet/substrate/provider";
import textsOnPage from "../textsOnPage";
import type { FormValues } from "./Form";
import type { StepData } from "./Steps";

const STEP_INDEX = 0;

const SelectSubstrateAddress: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { substrateAccounts } = useContext(SubstrateContext);

  const onChangeHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      const account = substrateAccounts.find(
        (account) => account.address === event.target.value
      );
      setFieldValue("substrate.account", account);
      setFieldValue("substrate.hex", addressToHex(event.target.value));
    },
    [setFieldValue, substrateAccounts]
  );

  if (!substrateAccounts.length) {
    return (
      <FieldViewOnly
        label={textsOnPage.form.steps[STEP_INDEX].fieldLabels.substrateAddress}
        value={"No accounts are available for selection."}
        formikPath={"substrate.account.address"}
      />
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="select-substrate-address">
        {textsOnPage.form.steps[STEP_INDEX].fieldLabels.substrateAddress}
      </InputLabel>
      <Select
        labelId="select-substrate-address"
        value={values.substrate.account?.address || ""}
        label={textsOnPage.form.steps[STEP_INDEX].fieldLabels.substrateAddress}
        id="substrate.account.address"
        name="substrate.account.address"
        onChange={onChangeHandler}
      >
        {substrateAccounts.map((address) => (
          <MenuItem key={address.address} value={address.address}>
            <Stack>
              <Typography variant="body1">{address.meta.name}</Typography>
              <Typography color="#a0a0a0" variant="caption">
                {address.address}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const Label: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <StepLabel
      StepIconProps={{
        style: {
          color:
            values.formParams.activeStep === STEP_INDEX ? "#90caf9" : "gray",
        },
      }}
      sx={{ pr: 4, pb: 1 }}
    >
      {textsOnPage.form.steps[STEP_INDEX].defaultStepLabel}
    </StepLabel>
  );
};

const Completed: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const handleStep = useCallback(() => {
    setFieldValue("formParams.activeStep", STEP_INDEX);
  }, [setFieldValue]);

  if (values.formParams.activeStep === STEP_INDEX) return null;

  return (
    <Stack sx={{ mb: 1 }}>
      <Alert severity="info">
        {textsOnPage.form.steps[STEP_INDEX].completed.description}
      </Alert>

      <Section
        value={values.substrate.account?.address}
        rightAction={
          values.formParams.activeStep === STEP_INDEX + 1 ? (
            <Button variant="text" onClick={handleStep}>
              {textsOnPage.form.steps[STEP_INDEX].completed.getBackButtonText}
            </Button>
          ) : undefined
        }
      />
    </Stack>
  );
};

const Main: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <StepContent>
      <Stack gap={2}>
        <Alert severity="info">
          {textsOnPage.form.steps[STEP_INDEX].description}
        </Alert>
        <SelectSubstrateAddress />
        {values.substrate.hex && (
          <FieldViewOnly
            label={
              textsOnPage.form.steps[STEP_INDEX].fieldLabels.substrateAddressHex
            }
            value={values.substrate.hex}
            formikPath={"substrate.hex"}
          />
        )}
        <Button
          fullWidth
          disabled={!Boolean(values.substrate.account)}
          variant="outlined"
          type="submit"
        >
          {textsOnPage.form.steps[STEP_INDEX].buttonText}
        </Button>
      </Stack>
    </StepContent>
  );
};

const SubstrateStep: StepData = {
  main: Main,
  completed: Completed,
  label: Label,
};

export default SubstrateStep;
